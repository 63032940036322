import Category from "./Category.jsx";
import React, { useState, useEffect, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import generatePDF, { Margin, Resolution } from "react-to-pdf";
import useAxios from "../../utils/useAxios";
import styles from "./Orkestertable.module.css";
import Card from "../UI/Card";
import Button from "../UI/Button.jsx";
import BackButton from "../UI/BackButton.jsx";

const Orkestertable = () => {
  const { groupID } = useParams();
  const [categories, setCategories] = useState([]);
  const [specialCategories, setSpecialCategories] = useState([]);
  const [errMsg, setErrMsg] = useState("");
  const [group, setGroup] = useState({ groupMembers: [] });
  const [render, setRender] = useState(0);
  const axios = useAxios();
  const pdfElementRef = useRef();

  const pdfOptionsA3 = {
    filename: `A3-${group.name}-${group.saved_at?.replace(/[^a-zA-Z0-9]/g,'_')}.pdf`,
    resolution: Resolution.HIGH,
    page: {
      format: 'a3',
      margin: Margin.SMALL,
      orientation: "landscape",
    },
    canvas: {
      // default is 'image/jpeg' for better size performance
      mimeType: "image/png",
      qualityRatio: 1,
    },
    overrides: {
      // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
      pdf: {
         compress: true
      },
   },


  };
  const pdfOptionsA4 = {
    filename: `A4-${group.name}-${group.saved_at?.replace(/[^a-zA-Z0-9]/g,'_')}.pdf`,
    page: {
      format: 'a4',
      margin: Margin.SMALL,
      orientation: "landscape",
    },
    canvas: {
      // default is 'image/jpeg' for better size performance
      mimeType: "image/png",
      qualityRatio: 1,
    },
    overrides: {
      // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
      pdf: {
         compress: true
      },
   },
 

  };

  var UPDATE_USER_URL = "/group/";

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getCategories = async () => {
      try {
        const response = await axios.get("/orkestercategories", {
          signal: controller.signal,
        });
        console.log(response.data)
        response.data.sort((a, b) => a.colorWeight - b.colorWeight);
        isMounted && setCategories(response.data.filter(category => !category.specialCategory));
        isMounted && setSpecialCategories(response.data.filter(category => category.specialCategory));
      } catch (err) {
        console.error(err);
      }
    };

    getCategories();

    return () => {
      isMounted = false;
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getGroup = async () => {
      try {
        const response = await axios.get(`/orkestergroup/${groupID}`, {
          signal: controller.signal,
        });
        isMounted && setGroup(response.data);
        console.log(response.data);
      } catch (err) {
        console.error(err);
      }
    };

    getGroup();

    return () => {
      isMounted = false;
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function onSubmitHandler(e) {
    e.preventDefault();

    var formData = new FormData(e.target);
    var serializedData = {};
    for (var [key, value] of formData.entries()) {
      serializedData[key] = value;
    }
    try {
      // eslint-disable-next-line no-unused-vars
      const response = await axios.post(
        `/orkestermembercell/${groupID}/${serializedData.member}/`,
        JSON.stringify(serializedData),
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      setGroup(response.data);
    } catch (error) {
      if (!error?.response) {
        setErrMsg("No Server Response!");
      } else if (error.response?.status === 409) {
        // Get messege from the response -> data.message (server is sending message: "xx")
        setErrMsg(error.response?.data.message);
      } else {
        setErrMsg("Registration Failed!");
      }
    }
  }
  function sortMembers(cellId) {
    let sortedMembers = group?.members?.filter((member) => {
      if (member.cells?.find((cell) => cell === cellId)) {
        member["color"] = getColorFromCategories(member.cells);
        return member;
      }
    });
    sortedMembers?.sort((a, b) => {
      return b.color.colorWeight - a.color.colorWeight;
    });
    return sortedMembers;
  }
  function getColorFromCategories(cells) {
    let color = {
      color: "",
      colorWeight: 0,
    };
    cells.forEach((cell) => {
      categories.forEach((category) => {
        if (category.cells.find((c) => c.id == cell)) {
          if (color.colorWeight < category.colorWeight) {
            color.color = category.color;
            color.colorWeight = category.colorWeight;
          }
        }
      });
    });
    return color;
  }
  async function deleteMembersHandler(
    addMemberList,
    removeMemberList,
    cellid,
    setShowModal
  ) {
    try {
      // eslint-disable-next-line no-unused-vars
      const response = await axios.post(
        `/orkestermembercell/${groupID}/${cellid}/`,
        {
          addMemberList: addMemberList,
          removeMemberList: removeMemberList,
        }
      );
      setGroup(response.data);
      setShowModal(false);
    } catch (error) {
      if (!error?.response) {
        setErrMsg("No Server Response!");
      } else if (error.response?.status === 409) {
        // Get messege from the response -> data.message (server is sending message: "xx")
        setErrMsg(error.response?.data.message);
      } else {
        setErrMsg("Registration Failed!");
      }
    }
  }
  return (
    <Card className={styles.orkester}>
      <div ref={pdfElementRef}>
        <header className={styles.header}>
          <div className={styles.leftHeader}>
            <BackButton data-html2canvas-ignore="true">Back</BackButton>
            <h2 className={styles.h2}>{group.name}</h2>
          </div>
          <div className={styles.rightHeader}>
          <Button className={styles.pdfButton} onClick={() => generatePDF(pdfElementRef, pdfOptionsA4)} data-html2canvas-ignore="true">
              Download A4
            </Button>
            <Button className={styles.pdfButton} onClick={() => generatePDF(pdfElementRef, pdfOptionsA3)} data-html2canvas-ignore="true">
              Download A3
            </Button>
            <span className={styles.savedAtLabel}>Saved at:</span>
            <span>{group.saved_at}</span>
          </div>
        </header>
        <div className={styles.categories}>
          {categories.map((category, i) => (
            <Category
              key={i}
              deleteMembersHandler={deleteMembersHandler}
              categoryData={category}
              members={group?.members}
              onSubmitHandler={onSubmitHandler}
              sortMembers={sortMembers}
            />
          ))}
        </div>
        <div className={styles.categories}>
          {specialCategories.map((category, i) => (
            <Category
              key={i}
              deleteMembersHandler={deleteMembersHandler}
              categoryData={category}
              members={group?.members}
              onSubmitHandler={onSubmitHandler}
              sortMembers={sortMembers}
              group={group}
            />
          ))}
        </div>

      </div>
    </Card>
  );
};

export default Orkestertable;
