import classes from "./Button.module.css";

const getDataAttributes = (inputProps = {}) =>
  Object.keys(inputProps).reduce((acc, key) => {
    if (key.startsWith("data-")) {
      acc[key] = inputProps[key];
    }

    return acc;
  }, {});

const Button = (props) => {
  const dataAttributes = getDataAttributes(props);
  return (
    <button
      className={`${props.className} ${classes.button}`}
      onClick={props.onClick}
      disabled={props.disabled}
      {...dataAttributes}
    >
      {props.children}
    </button>
  );
};

export default Button;
