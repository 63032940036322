import classes from "./InputContainer.module.css"

const InputContainer = (props) => {

    return (
        <div className={`${props.className} ${classes.container}`}>
            {props.children}
        </div>
    )
}

export default InputContainer