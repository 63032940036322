import { useNavigate, Link } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import useAxios from "../../utils/useAxios";
import AuthContext from "../../context/AuthContext";
import Card from "../UI/Card";
import Button from "../UI/Button";

import styles from "./Home.module.css";

const Home = () => {
  const [groups, setGroups] = useState(null);

  const { user, logoutUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const axios = useAxios();

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getGroups = async () => {
      try {
        const response = await axios.get("/orkestergroups", {
          signal: controller.signal,
        });
        isMounted && setGroups(response.data);
      } catch (err) {
        console.error(err);
      }
    };

    getGroups();

    return () => {
      isMounted = false;
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <Card className={styles.home}>
      <h1>Home</h1>
      <br />
      <div className={styles.cardContainer}>
        <div className={styles.linkContainer}>

        <Link className={styles.link} to="/create_group">
          <Card className={`${styles.card} ${styles.createGroup}`}>
              <i className="gg-add-r"></i>
          </Card>
        </Link>
        </div>
        {groups?.map((group, i) => (
          <Card key={i} className={styles.card}>
              <Link className={styles.link} to={`/orkester/${group.id}`}>
              <div className={styles.cardHead} data-name={group.name}>
                <span>{group.name}</span>
              </div>
          </Link>
              <div className={styles.cardBody}>
                <span>{group.members.length}</span>
                <Link className={styles.gear} to={`/edit_group/${group.id}`}>&#9881;</Link>
              </div>
            </Card>
        ))}
      </div>
      <div className="flexGrow">
        <Button className={styles.signOut} onClick={() => logoutUser()}>Sign Out</Button>
      </div>
    </Card>
  );
};

export default Home;
