import { useContext } from "react";
import { Link } from "react-router-dom";
import { useRef, useState, useEffect } from "react";
import AuthContext from "../../context/AuthContext";
import styles from "./Register.module.css";

import Card from "../UI/Card";
import InputContainer from "../UI/InputContainer";
import InfoBox from "../UI/InfoBox";
import Button from "../UI/Button";

// Regex für E-Mail-Adressen
const EMAIL_REGEX =
  /^([a-zA-Z0-9_\-\\.]+)@([a-zA-Z0-9_\-\\.]+)\.([a-zA-Z]{2,5})$/;
//const PWD_REGEX = /^[a-zA-Z0-9]{8,16}$/;
const PWD_REGEX = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,32}$/;


function Register() {
  const userRef = useRef();
  const errRef = useRef();
  const { registerUser } = useContext(AuthContext);

  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);

  const [password, setPassword] = useState("");
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const [matchPwd, setMatchPwd] = useState("");
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const [errMsg, setErrMsg] = useState("");
  const [srvMsg, setSrvMsg] = useState("");
  const [success, setSuccess] = useState(false);

  //   wird geladen beim laden der View
  useEffect(() => {
    userRef.current.focus();
  }, []);

  //   Email Validation, immer wenn die Email sich ändert [email] am ende, wird diese aufgerufen
  useEffect(() => {
    const result = EMAIL_REGEX.test(email);
    setValidEmail(result);
  }, [email]);

  useEffect(() => {
    const result = PWD_REGEX.test(password);
    setValidPwd(result);
    const match = password === matchPwd;
    setValidMatch(match);
  }, [password, matchPwd]);

  //   Nach dem eine Fehlermeldung angezeigt wurde, und eins der Felder im [user, usw..] geändert wird,
  // wird die Fehlermeldung Resetet, da der User diese "gesehen" hat.
  useEffect(() => {
    setErrMsg("");
  }, [email, password, matchPwd]);

  useEffect(() => {
    setSrvMsg("");
  }, [email, password, matchPwd]);

  async function handleSubmit(e) {
    e.preventDefault();
    
    registerUser(password, matchPwd, email, setErrMsg)
    errRef.current.focus();
    }

  // aria-live => Barrierefreies Webdesign: https://www.barrierefreies-webdesign.de/knowhow/live-regions/attribute.html
  return (
    <Card className={styles.register}>
      {success ? (
        <section>
          <h1>Success</h1>
          {srvMsg}
          <p>
            Pleace click on <Link to="/login">Login</Link> and go to the login
            page.
          </p>
        </section>
      ) : (
        <section>
          <p
            ref={errRef}
            className={errMsg ? styles.errMsg : styles.offscreen}
            aria-live="assertive"
          >
            {errMsg}
          </p>
          <h1>Register</h1>
          <p style={{backgroundColor:'lightgreen'}}> 
            Before you can log in, you must confirm your email address.
            Please check your email inbox after registering.
          </p>
          <form onSubmit={handleSubmit}>
            <InputContainer>
              <label htmlFor="email">
                Email:
                <span className={validEmail ? "valid" : "hide"}>✅</span>
                <span className={validEmail || !email ? "hide" : "invalid"}>
                  ❌
                </span>
              </label>
              <input
                type="text"
                id="email"
                ref={userRef}
                autoComplete="off"
                onChange={(e) => {
                  setEmail(e.target.value.toLowerCase());
                }}
                required
                aria-invalid={validEmail ? "false" : "true"}
                aria-describedby="uidnote"
                onFocus={() => setEmailFocus(true)}
                onBlur={() => setEmailFocus(false)}
                />
              <p
                id="uidnote"
                className={
                  emailFocus && email && !validEmail
                  ? styles.instructions
                  : "hide"
                }
                >
                Must be a valid email address. <br />
                Letters, numbers, underscores, hyphens, dots, plus sign allowed.
              </p>
            </InputContainer>
            <InputContainer>
              <label htmlFor="password">
                Password:
                <span className={validPwd ? "valid" : "hide"}>✅</span>
                <span className={validPwd || !password ? "hide" : "invalid"}>
                  ❌
                </span>
              </label>
              <input
                type="password"
                id="password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                required
                aria-invalid={validPwd ? "false" : "true"}
                aria-describedby="pwdnote"
                onFocus={() => setPwdFocus(true)}
                onBlur={() => setPwdFocus(false)}
              />
              <p
                id="pwdnote"
                className={pwdFocus && !validPwd ? styles.instructions : "hide"}
                >
                8 to 32 characters. <br />
                Must include upercase und lovercase letters, numbers.
              </p>
            </InputContainer>
            <InputContainer>
              <label htmlFor="confirm_pwd">
                Confirm Password:
                <span className={validMatch && matchPwd ? "valid" : "hide"}>
                  ✅
                </span>
                <span className={validPwd || !matchPwd ? "hide" : "invalid"}>
                  ❌
                </span>
              </label>
              <input
                type="password"
                id="confirm_pwd"
                onChange={(e) => setMatchPwd(e.target.value)}
                value={matchPwd}
                required
                aria-invalid={validMatch ? "false" : "true"}
                aria-describedby="confirmnote"
                onFocus={() => setMatchFocus(true)}
                onBlur={() => setMatchFocus(false)}
                />
              <p
                id="confirmnote"
                className={
                  matchFocus && !validMatch ? styles.instructions : "hide"
                }
                >
                Must match the first password input field.
              </p>
            </InputContainer>

            <Button
              disabled={!validPwd || !validMatch ? true : false}
            >
              Sign Up
            </Button>
          </form>
          <InfoBox>
            <span>Already registered?</span>
            <Link to="/login">Login</Link>
          </InfoBox>
        </section>
      )}
    </Card>
  );
}

export default Register;
