import styles from "./EditGroup.module.css"
import { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import useAxios from "../../utils/useAxios";
import Card from "../UI/Card";
import GroupFormular from "../Formulars/GroupFormular";
import BackButton from "../UI/BackButton.jsx";

const EditGroupPage = (props) => {

    const { groupID } = useParams();
    const [errMsg, setErrMsg] = useState("");
    const [group, setGroup] = useState({});

    const axios = useAxios();
    const navigateTo = useNavigate();

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
    
        const getGroup = async () => {
            try {
                const response = await axios.get(`/orkestergroup/${groupID}`, {
                    signal: controller.signal,
                });
                isMounted && setGroup(response.data);
            } catch (err) {
                console.error(err);
            }
        };
    
        getGroup();
    
        return () => {
            isMounted = false;
            controller.abort();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    
    return (
        <Card className={styles.editGroup}> 
            <header className={styles.header}>

            <BackButton>
                Back
            </BackButton>
            <h1>Edit Group</h1>
            </header>
            <GroupFormular group={group}></GroupFormular>
        </Card>

    )
}

export default EditGroupPage;