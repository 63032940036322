import classes from "./Card.module.css"
import { forwardRef } from "react"

const Card = (props, ref) => {
    return (
        <div 
            className={`${props.className} ${classes.card}`}
            style={props?.style}
            ref={ref}
        >
            {props.children}
        </div>
    );
}
export default forwardRef(Card);