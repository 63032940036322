import classes from "./Cell.module.css";
import { useState, useEffect } from "react";
import Card from "../UI/Card";
import Modal from "../UI/Modal";
import CellMemberManagement from "./CellMemberManagement";

const Cell = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [showExplanationModal, setShowExplanationModal] = useState(false);

  const inCellList = props.members?.filter((member) => {
    if (member.cells?.find((cell) => cell === props.cellData.id)) {
      return member;
    }
  });
  const notInCellList = props.members?.filter((member) => {
    if (!member.cells?.find((cell) => cell === props.cellData.id)) {
      return member;
    }
  });

  return (
    <Card className={`${props.className} ${classes.cell}`}>
      <div
        className={`${classes.cellHead} ${props.colorWeight==99?classes.blueCategory:''}`}
        style={{ backgroundColor: props.color }}
      >
        <span className={classes.title}  onClick={()=>{setShowExplanationModal(true)}}>{props.cellData.title}<i className={classes.helpIcon} data-html2canvas-ignore="true">?</i></span>

 
        <span
          className={classes.subtitle}
          style={{ backgroundColor: props.color }}
        >
          <span>{props.cellData.description}</span>
        </span>
      </div>
      <div className={classes.cellBody} onClick={() => setShowModal(true)}>
        {props.sortedMembers(props.cellData.id)?.map((member, i) => {
            return (
              <span
                key={i}
                className={classes.member}
                style={{ backgroundColor: member.color.color }}
              >
                {`${member.firstName} ${member.lastName[0]}.`}
              </span>
            );

        })}
       
      </div>
        <Modal
          title={props.cellData.title}
          show={showModal}
          onClose={() => setShowModal(false)}
          headColor={{ backgroundColor: props.color }}
        >
          <CellMemberManagement
            inCellList={inCellList}
            notInCellList={notInCellList}
            cellid={props.cellData.id}
            deleteMembersHandler={props.deleteMembersHandler}
            setShowModal={setShowModal}
          ></CellMemberManagement>
        </Modal>
        <Modal
          title={props.cellData.title}
          show={showExplanationModal}
          onClose={() => setShowExplanationModal(false)}
          className={classes.explanationModal}
          headColor={{ backgroundColor: props.color }}
        >
          <p>{props.cellData.explanation}</p>
        </Modal>
    </Card>
  );
};

export default Cell;
