import { useLocation, Navigate, Outlet } from "react-router-dom";

import AuthContext from "../context/AuthContext";

import { useContext } from "react";

const RequireAuth = () => {
  const { user } = useContext(AuthContext);
  const location = useLocation();
  let isAuthenticated = user?.exp * 1000 > new Date().getTime();
  
  return (
    isAuthenticated ? (
    <Outlet />
    ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  ));
};

export default RequireAuth;
