import React from "react"

import Card from "./Card"
import classes from "./Modal.module.css"


function Modal(props){
    if(!props.show){
        return null
    }

    return (
        <div className={classes.modal}>
            <Card className={`${classes.modalContent} ${props.className}`}>
                <i className={`${classes.closeButton} gg-close`} onClick={props.onClose}></i>
                <div className={classes.modalHeader} style={props.headColor}>
                    <h4 className={classes.modalTitle}>{props.title}</h4>
                </div>
                <div className={classes.modalBody}>
                    {props.children}
                </div>
                <div className={classes.modalFooter}>

                </div>
            </Card>

        </div>
    )
}

export default Modal