import classes from "./CellMemberManagement.module.css";

import { useState } from "react";
import Card from "../UI/Card";
import Button from "../UI/Button";

const CellMemberManagement = (props) => {
  const [inCellList, setInCellList] = useState(props.inCellList);
  const [notInCellList, setNotInCellList] = useState(props.notInCellList);
  const [addMemberList, setAddMemberList] = useState([]);
  const [removeMemberList, setRemoveMemberList] = useState([]);

  function removeMember(e) {
    let index = e.target.dataset.index;
    let member = e.target.dataset.member;

    const removedMember = inCellList[index];

    setInCellList((prevArray) => {
      const newArray = [...prevArray];
      newArray.splice(index, 1);
      return newArray;
    });
    setNotInCellList((prevArray) => [...prevArray, removedMember]);

    if(!removeMemberList.find((element) =>  element == member)){
        if(removedMember.cells.find((element) => element == props.cellid)) {
            setRemoveMemberList((prevArray) => [...prevArray, parseInt(member)])
        }
    }
    const addIndex = addMemberList.findIndex((element) => element == member )
    if(addIndex >= 0){
        setAddMemberList((prevArray) => {
            const newArray = [...prevArray];
            newArray.splice(addIndex, 1);
            return newArray
        });
    }
  }
  function addMember(e) {
    let index = e.target.dataset.index;
    let member = e.target.dataset.member;

    const removedMember = notInCellList[index];

    setNotInCellList((prevArray) => {
      const newArray = [...prevArray];
      newArray.splice(index, 1);
      return newArray;
    });
    setInCellList((prevArray) => [...prevArray, removedMember]);

    if(!addMemberList.find((element) =>  element == member)){
        if(!removedMember.cells.find((element) => element == props.cellid)){
            setAddMemberList((prevArray) => [...prevArray, parseInt(member)])
        }
    }
    const removeIndex = removeMemberList.findIndex((element) => element == member )
    if(removeIndex >= 0){
        setRemoveMemberList((prevArray) => {
            const newArray = [...prevArray];
            newArray.splice(removeIndex, 1);
            return newArray
        });
    }
  }

  return (
    <div className={classes.modalMemberBody}>
      <div className={classes.modalMemberLists}>
        <div className={classes.modalMemberList}>
          <span>Available Members</span>
          <Card className={`${classes.addMemberList}`}>
            {notInCellList?.map((member, i) => {
              return (
                <Card key={i} value={member.id} className={classes.modalMember}>
                  <span>{`${member.firstName} ${member.lastName[0]}.`}</span>
                  <i
                    className="gg-add-r"
                    onClick={addMember}
                    data-index={i}
                    data-member={member.id}
                  ></i>
                </Card>
              );
            })}
          </Card>
        </div>
        <div className={classes.modalMemberList}>
          <span>Members in Cell</span>
          <Card className={`${classes.removeMemberList}`}>
            {inCellList?.map((member, i) => {
              return (
                <Card key={i} value={member.id} className={classes.modalMember}>
                  <span>{`${member.firstName} ${member.lastName[0]}.`}</span>
                  <i
                    onClick={removeMember}
                    className="gg-remove-r"
                    data-index={i}
                    data-member={member.id}
                  ></i>
                </Card>
              );
            })}
          </Card>
        </div>
      </div>
      <div className={classes.modalButtons}>
        <Button onClick={()=>{props.deleteMembersHandler(addMemberList, removeMemberList, props.cellid, props.setShowModal)}} className={classes.modalSaveButton}>Save</Button>
        <Button className={classes.cancelButton} onClick={()=>props.setShowModal(false)}>Cancel</Button>
      </div>
    </div>
  );
};

export default CellMemberManagement;
