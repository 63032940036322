import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Card from "../UI/Card";
import Button from "../UI/Button";
import InfoBox from "../UI/InfoBox";
import InputContainer from "../UI/InputContainer";
import classes from "./ResetPassword.module.css";

const BASE_URL = process.env.REACT_APP_API_URL;
const RESET_URL = "/reset_password/";

function ResetPassword() {
  // Regex für E-Mail-Adressen
  const EMAIL_REGEX =
    /^([a-zA-Z0-9_\-\\.]+)@([a-zA-Z0-9_\-\\.]+)\.([a-zA-Z]{2,5})$/;

  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);

  const [info, setInfo] = useState("");
  const [isSentMail, setIsSentMail] = useState(false);

  //   Email Validation, immer wenn die Email sich ändert [email] am ende, wird diese aufgerufen
  useEffect(() => {
    const result = EMAIL_REGEX.test(email);
    setValidEmail(result);
  }, [email]);

  async function sendMail(e) {
    e.preventDefault();
    const response = await fetch(`${BASE_URL}${RESET_URL}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
      }),
    });
    if (response.status === 200) {
      setInfo(
        "If there is an Account with your entered Email, you will receive an Email then."
      );
    } else {
      setInfo("Server is currently no available.");
    }
    setIsSentMail(true);
  }

  return (
    <Card className={classes.reset_password}>
      <header className={classes.header}>
        <Link to="/" className={classes.backLink}>
          <i className="gg-push-left"></i>
        </Link>
        <h1 className={classes.h2}>Reset Password</h1>
      </header>
      {!isSentMail ? (
        <form onSubmit={sendMail}>
          <InputContainer>
            <label htmlFor="email">Email:</label>
            <input
              type="text"
              id="email"
              autoComplete="off"
              onChange={(e) => setEmail(e.target.value.toLowerCase())}
              value={email}
              required
            />
            <p
              id="uidnote"
              className={email && !validEmail ? classes.instructions : "hide"}
            >
              Must be a valid email address.
            </p>
          </InputContainer>
          <Button disabled={!validEmail}>Send Mail</Button>
        </form>
      ) : (
        <InfoBox className={classes.signup}>
          <span>{info}</span>
        </InfoBox>
      )}
    </Card>
  );
}

export default ResetPassword;
