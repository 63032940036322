import styles from "./GroupFormular.module.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAxios from "../../utils/useAxios";
import InputContainer from "../UI/InputContainer";
import Card from "../UI/Card";
import Button from "../UI/Button";
import Modal from "../UI/Modal";

const GroupFormular = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState("");
  const [members, setMembers] = useState([]);
  const [errMsg, setErrMsg] = useState("");

  const axios = useAxios();
  const navigateTo = useNavigate();

  useEffect(() => {
    if (props.group) {
      const { name, members } = props.group;
      setName(name);
      setMembers(members);
    }
  }, [props.group]);
  async function saveGroup() {
    try {
      const response = await axios.put(
        `/orkestergroup/${props.group.id}/`,
        {
          user: props.group.user,
          name: name,
          members: members,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      navigateTo("/");
    } catch (error) {
      if (!error?.response) {
        setErrMsg("No Server Response!");
      } else if (error.response?.status === 409) {
        // Get messege from the response -> data.message (server is sending message: "xx")
        setErrMsg(error.response?.data.message);
      } else {
        setErrMsg("Registration Failed!");
      }
    }
  }
  async function createGroup() {
    try {
      const response = await axios.post(
        `/orkestergroup/`,
        {
          name: name,
          members: members,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      navigateTo("/");
    } catch (error) {
      console.log(error);
      if (!error?.response) {
        setErrMsg("No Server Response!");
      } else if (error.response?.status === 409) {
        // Get messege from the response -> data.message (server is sending message: "xx")
        setErrMsg(error.response?.data.message);
      } else {
        setErrMsg("Registration Failed!");
      }
    }
  }
  function addMember() {
    setMembers([...members, { firstName: "", lastName: "" }]);
  }
  function setFirstName(member, value) {
    return { ...member, firstName: value };
  }
  function setLastName(member, value) {
    return { ...member, lastName: value };
  }
  function updateMember(updateMember, value, setValue) {
    const updatedMembers = members.map((member) => {
      if (member === updateMember) {
        return setValue(member, value);
      }
      return member;
    });
    setMembers(updatedMembers);
  }
  async function deleteGroup() {
    try {
      const response = await axios.delete(
        `/orkestergroup/${props.group.id}/`,
        {},
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      navigateTo("/");
    } catch (error) {
      if (!error?.response) {
        setErrMsg("No Server Response!");
      } else if (error.response?.status === 409) {
        // Get messege from the response -> data.message (server is sending message: "xx")
        setErrMsg(error.response?.data.message);
      } else {
        setErrMsg("Registration Failed!");
      }
    }
  }
  function removeMember(removeMember) {
    setMembers(members.filter((member) => member !== removeMember));
  }

  return (
    <div>
      <InputContainer className={styles.groupNameInputContainer}>
        <label htmlFor="name">Name:</label>
        <div className={styles.groupNameInput}>
          <input
            type="text"
            id="name"
            autoComplete="off"
            onChange={(e) => {
              setName(e.target.value);
            }}
            value={name ?? ""}
            maxlength="50"
            required
          />
          <span className={styles.remainCharsSpan}>{name?.length}/50</span>
        </div>
      </InputContainer>
      <div className={styles.membersHead}>Members:</div>
      <Card className={styles.membersCard}>
        <div className={styles.membersContainer}>
          {members?.map((member, i) => (
            <div key={i} className={styles.members}>
              <InputContainer className={`${styles.memberInput}`}>
                <label htmlFor="firstName">First Name:</label>
                <input
                  type="text"
                  id="firstName"
                  autoComplete="off"
                  onChange={(e) =>
                    updateMember(member, e.target.value, setFirstName)
                  }
                  value={members[i].firstName}
                  required
                />
              </InputContainer>
              <InputContainer className={`${styles.memberInput}`}>
                <label htmlFor="lastName">Last Name:</label>
                <input
                  type="text"
                  id="lastName"
                  autoComplete="off"
                  onChange={(e) =>
                    updateMember(member, e.target.value, setLastName)
                  }
                  value={members[i].lastName}
                  required
                />
              </InputContainer>
              <Button
                className={styles.removeButton}
                onClick={(e) => removeMember(member)}
              >
                <i className="gg-close-r"></i>
              </Button>
            </div>
          ))}
        </div>
        <Button className={styles.addButton} onClick={addMember}>
          <i className="gg-add-r"></i>
        </Button>
      </Card>
      <div className={styles.buttonGroup}>
        {props.group ? (
          <Button className={styles.button} onClick={saveGroup}>
            Save Group
          </Button>
        ) : (
          <Button className={styles.button} onClick={createGroup}>
            Create Group
          </Button>
        )}
        {props.group ? (
          <Button
            className={styles.deleteButton}
            onClick={() => setShowModal(true)}
          >
            Delete Group
          </Button>
        ) : (
          ""
        )}
        <Modal
          title="Are you sure you want to delete this group?"
          show={showModal}
          onClose={() => setShowModal(false)}
        >
          <div className={styles.flexDirection}>

          <Button className={styles.deleteButton} onClick={deleteGroup}>
            Delete Group
          </Button>
          <Button
            className={styles.cancelButton}
            onClick={() => {
              setShowModal(false);
            }}
          >
            Cancel
          </Button>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default GroupFormular;
