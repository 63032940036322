import Cell from "./Cell";
import EditableCell from "./EditableCell";
import Card from "../UI/Card"
import classes from "./Category.module.css";
import { useState } from "react";
import Modal from "../UI/Modal";

const Category = (props) => {
  const [showExplanationModal, setShowExplanationModal] = useState(false)
    return (
    <div className={`${classes.category} ${props.categoryData.specialCategory?classes.blueCategory:''}`}>
      
      {!props.categoryData.specialCategory ? props.categoryData.cells.map((cell, i) => (
        <Cell
          key={i}
          className={classes.cell}
          deleteMembersHandler={props.deleteMembersHandler}
          cellData={cell}
          categoryId={props.categoryData.id}
          color={props.categoryData.color}
          colorWeight={props.categoryData.colorWeight}
          members={props.members}
          onSubmitHandler={props.onSubmitHandler}
          sortedMembers={props.sortMembers}
        />
      )):props.categoryData.cells.map((cell, i) => (
        <EditableCell
          key={i}
          className={classes.cell}
          deleteMembersHandler={props.deleteMembersHandler}
          cellData={cell}
          categoryId={props.categoryData.id}
          color={props.categoryData.color}
          colorWeight={props.categoryData.colorWeight}
          members={props.members}
          onSubmitHandler={props.onSubmitHandler}
          sortedMembers={props.sortMembers}
          group={props?.group}
        />))}
      <Card
        className={classes.endCell}
        style={{ backgroundColor: props.categoryData.color }}
      >
        <i className={classes.helpIcon} onClick={()=>{setShowExplanationModal(true)}} data-html2canvas-ignore="true">?</i>
        <span className={classes.endCellTitle}>{props.categoryData.title} </span>
        <span>{props.categoryData.description}</span>
      </Card>
      <Modal
          title={props.categoryData.title}
          show={showExplanationModal}
          onClose={() => setShowExplanationModal(false)}
          className={classes.explanationModal}
          headColor={{ backgroundColor: props.categoryData.color }}
        >
          <p>{props.categoryData.explanation}</p>
        </Modal>
    </div>
  );
};

export default Category;
