import { createContext, useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import { useNavigate, useLocation } from "react-router-dom";

const baseURL = process.env.REACT_APP_API_URL;

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
  /** schaut nach, ob auth Tokens bereits gesetzt wurden und initialisiert authTokens damit,
   * oder setzt ihn auf null */
  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  /** useState für user, wenn authToken vorhanden, dekodiert authToken in userState */
  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwt_decode(localStorage.getItem("authTokens"))
      : null
  );

  const [loading, setLoading] = useState(true);

  const navigateTo = useNavigate();
  const location = useLocation();

  /** noch umzuschreiben als function
   */
  const loginUser = async (email, password, setErrMsg) => {
    /** noch umzuschreiben in AXIOS
     * fetch der tokens, übergibt username und password
     */
    const response = await fetch(`${baseURL}/login/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        password,
      }),
    });

    /**rückgabe der tokens */
    const data = await response.json();
    /** wenn Server OK dann werden Tokens gesetzt
     * und User in state gesetzt
     * und im Local Storage gesetzt
     * und dann weitergeleitet auf root
     */
    if (response.status === 200) {
      setAuthTokens(data);
      setUser(jwt_decode(data.access));
      localStorage.setItem("authTokens", JSON.stringify(data));
      const from = location.state?.from?.pathname || "/";
      navigateTo(from, {replace: true});
    } if (response.status === 401){
      setErrMsg(data.detail)
    } else {
      setErrMsg("Something went wrong!");
    }
    
  };

  /** registriert user */
  const registerUser = async (password, password_confirm, email, setErrMsg) => {
    const response = await fetch(`${baseURL}/signup/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        password,
        password_confirm,
        email,
      }),
    });

    if (response.status === 201) {
      navigateTo("/login");
    } else {
      setErrMsg("Something went wrong!");
    }
  };

  /** logout mit resets der states und storages und redirect auf root */
  const logoutUser = () => {
    setAuthTokens(null);
    setUser(null);
    localStorage.removeItem("authTokens");
    navigateTo("/login");
  };

  const contextData = {
    user,
    setUser,
    authTokens,
    setAuthTokens,
    registerUser,
    loginUser,
    logoutUser,
  };

  /** user wird hier aktuell gehalten */
  useEffect(() => {
    if (authTokens) {
      setUser(jwt_decode(authTokens.access));
    }
    setLoading(false);
  }, [authTokens, loading]);

  /** zeigt nur die children an, wenn alle auth Prozesse abgeschlossen sind */
  return (
    <AuthContext.Provider value={contextData}>
      {loading ? null : children}
    </AuthContext.Provider>
  );
};
