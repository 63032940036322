import React from "react";
import "./App.css";
import "./gg-icons.css";
import { Routes, Route } from "react-router-dom";
import Layout from "./utils/Layout";
import Home from "./components/HomePage/Home";
import LoginPage from "./components/LoginPage/Login";
import RegisterPage from "./components/RegisterPage/Register";
import CreateGroupPage from "./components/CreateGroupPage/CreateGroup";
import EditGroupPage from "./components/EditGroupPage/EditGroup";
import Welcome from "./components/MailConfirmPage/Welcome";
import RequireAuth from "./utils/RequireAuth";
import Orkestertable from "./components/OrkesterPage/Orkestertable";
import ResetPassword from "./components/PasswordPages/ResetPassword";
import SetNewPassword from "./components/PasswordPages/SetNewPassword";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* public routes */}
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="confirm/:confirmationCode" element={<Welcome />} />
        <Route path="/reset_password" element={<ResetPassword />} />
        <Route path="/set_new_password/:resetCode" element={<SetNewPassword />} />
        {/* <Route path="/logout" element={<Logout />} /> */}
        
        {/* protected routes */}
        <Route element={<RequireAuth />}>
          <Route path="/" element={<Home />} />
          <Route path="/create_group" element={<CreateGroupPage />} />
          <Route path="/orkester/:groupID" element={<Orkestertable />} />
          <Route path="/edit_group/:groupID" element={<EditGroupPage />} />
        </Route>
      </Route>
    </Routes>

  );
}

export default App;
