import styles from "./CreateGroup.module.css";
import { Link, useNavigate } from "react-router-dom";

import useAxios from "../../utils/useAxios";
import { useState, useEffect, useContext } from "react";
import Modal from "../UI/Modal";
import AuthContext from "../../context/AuthContext";
import Card from "../UI/Card";
import GroupFormular from "../Formulars/GroupFormular";
import BackButton from "../UI/BackButton.jsx";

const CreateGroupPage = (props) => {
  const CREATE_GROUP_URL = "/orkestergroup/";
  const axiosPrivate = useAxios();
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const [errMsg, setErrMsg] = useState("");
  const [groupName, setGroupName] = useState("");
  const [groupMembers, setGroupMembers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [linkToGroup, setLinkToGroup] = useState(null);

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      // eslint-disable-next-line no-unused-vars
      const response = await axiosPrivate.post(
        CREATE_GROUP_URL,
        JSON.stringify({ name: groupName, user: user, members: groupMembers }),
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      //setLinkToGroup(`/orkester/${response.data.group_id}`)
      //setShowModal(true)
    } catch (error) {
      if (!error?.response) {
        setErrMsg("No Server Response!");
      } else if (error.response?.status === 409) {
        // Get messege from the response -> data.message (server is sending message: "xx")
        setErrMsg(error.response?.data.message);
        setShowModal(true);
      } else {
        setErrMsg("Registration Failed!");
      }
    }
  }
  return (
    <Card className={styles.createGroup}>
      <header className={styles.header}>
        <BackButton>
          Back
        </BackButton>
        <h1>Create Group</h1>
      </header>
      <GroupFormular></GroupFormular>
      <Modal
        title="Create Group"
        show={showModal}
        onClose={() => setShowModal(false)}
        onBack={() => {
          setShowModal(false);
          navigate("/");
        }}
        onToGroup={() => {
          setShowModal(false);
          navigate(linkToGroup);
        }}
      ></Modal>
    </Card>
  );
};

export default CreateGroupPage;
