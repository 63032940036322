import styles from "./InfoBox.module.css";

const InfoBox = (props) => {
    return (
        <div className={styles.box}>
            {props.children}
        </div>
    )
}

export default InfoBox;