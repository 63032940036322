import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";

import Card from "../UI/Card";
import Button from "../UI/Button";
import InfoBox from "../UI/InfoBox";
import InputContainer from "../UI/InputContainer";
import classes from "./SetNewPassword.module.css";

const BASE_URL = process.env.REACT_APP_API_URL;
const CONFIRM_URL = "/check_reset_password_token/";
const SET_NEW_PASSWORD_URL = "/set_new_password/";

//const PWD_REGEX = /^[a-zA-Z0-9]{8,16}$/;
const PWD_REGEX = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,32}$/;

function SetNewPassword() {
  const { resetCode } = useParams();
  const [info, setInfo] = useState("");

  const [tokenValid, setTokenValid] = useState(true);
  const [setPasswordSuccessful, setSetPasswordSuccessful] = useState(false);

  const [password, setPassword] = useState("");
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const [matchPwd, setMatchPwd] = useState("");
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  useEffect(() => {
    const result = PWD_REGEX.test(password);
    setValidPwd(result);
    const match = password === matchPwd;
    setValidMatch(match);
  }, [password, matchPwd]);

  async function check_reset_token() {
    console.log(resetCode);
    const response = await fetch(`${BASE_URL}${CONFIRM_URL}${resetCode}`);
    const data = await response.json();

    setTokenValid(data.tokenValid);
  }
  useEffect(() => {
    check_reset_token();
  }, []);
  async function handleSubmit(e) {
    e.preventDefault();

    const response = await fetch(`${BASE_URL}${SET_NEW_PASSWORD_URL}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        password,
        resetCode,
      }),
    });
    if (response.status === 200) {
      setSetPasswordSuccessful(true);
    }
  }
  return (
    <Card className={classes.reset_password}>
      <header className={classes.header}>
        <h1 className={classes.h2}>Set a new Password</h1>
      </header>
      {tokenValid ? (
        setPasswordSuccessful ? (
          <InfoBox className={classes.signup}>
            <p>
              Password was set successfuly
              <br />
              Back to the <Link to="/">Login Page</Link>.
            </p>
          </InfoBox>
        ) : (
          <form onSubmit={handleSubmit}>
            <InputContainer>
              <label htmlFor="password">
                Password:
                <span className={validPwd ? "valid" : "hide"}>✅</span>
                <span className={validPwd || !password ? "hide" : "invalid"}>
                  ❌
                </span>
              </label>
              <input
                type="password"
                id="password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                required
                aria-invalid={validPwd ? "false" : "true"}
                aria-describedby="pwdnote"
                onFocus={() => setPwdFocus(true)}
                onBlur={() => setPwdFocus(false)}
              />
              <p
                id="pwdnote"
                className={
                  pwdFocus && !validPwd ? classes.instructions : "hide"
                }
              >
                8 to 32 characters. <br />
                Must include upercase und lovercase letters, numbers.
              </p>
            </InputContainer>
            <InputContainer>
              <label htmlFor="confirm_pwd">
                Confirm Password:
                <span className={validMatch && matchPwd ? "valid" : "hide"}>
                  ✅
                </span>
                <span className={validPwd || !matchPwd ? "hide" : "invalid"}>
                  ❌
                </span>
              </label>
              <input
                type="password"
                id="confirm_pwd"
                onChange={(e) => setMatchPwd(e.target.value)}
                value={matchPwd}
                required
                aria-invalid={validMatch ? "false" : "true"}
                aria-describedby="confirmnote"
                onFocus={() => setMatchFocus(true)}
                onBlur={() => setMatchFocus(false)}
              />
              <p
                id="confirmnote"
                className={
                  matchFocus && !validMatch ? classes.instructions : "hide"
                }
              >
                Must match the first password input field.
              </p>
            </InputContainer>
            <Button disabled={!validPwd || !validMatch ? true : false}>
              Set Password
            </Button>
          </form>
        )
      ) : (
        <InfoBox className={classes.signup}>
          <p>
            The token has expired.
            <br />
            You can receive a new email <Link to="/reset_password">
              here
            </Link>{" "}
            or go back to the <Link to="/">Login Page</Link>.
          </p>
        </InfoBox>
      )}
    </Card>
  );
}

export default SetNewPassword;
