import classes from "./BackButton.module.css";
import { useNavigate } from "react-router-dom";

const getDataAttributes = (inputProps = {}) =>
  Object.keys(inputProps).reduce((acc, key) => {
    if (key.startsWith("data-")) {
      acc[key] = inputProps[key];
    }

    return acc;
  }, {});

const BackButton = (props) => {
    const navigateTo = useNavigate();
    const dataAttributes = getDataAttributes(props);

    return (
        <button className={`${props.className} ${classes.button}`} onClick={()=>{navigateTo("/")}} disabled={props.disabled} {...dataAttributes}>{props.children}</button>
    )
}

export default BackButton;