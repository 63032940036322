import { useContext } from "react";
import { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import AuthContext from "../../context/AuthContext";
import Card from "../UI/Card";
import InputContainer from "../UI/InputContainer";
import Button from "../UI/Button";
import InfoBox from "../UI/InfoBox";
import classes from "./Login.module.css";

function Login() {
  // Regex für E-Mail-Adressen
  const EMAIL_REGEX =
    /^([a-zA-Z0-9_\-\\.]+)@([a-zA-Z0-9_\-\\.]+)\.([a-zA-Z]{2,5})$/;

  const { loginUser } = useContext(AuthContext);

  const userRef = useRef();
  const errRef = useRef();

  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);

  const [password, setPassword] = useState("");
  const [errMsg, setErrMsg] = useState("");

  //   wird geladen beim laden der View
  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [email, password]);

  //   Email Validation, immer wenn die Email sich ändert [email] am ende, wird diese aufgerufen
  useEffect(() => {
    const result = EMAIL_REGEX.test(email);
    setValidEmail(result);
  }, [email]);

  async function handleSubmit(e) {
    e.preventDefault();

    loginUser(email, password, setErrMsg);
    errRef.current.focus();
  }

  return (
    <Card className={classes.login}>
      <p
        ref={errRef}
        className={errMsg ? classes.errMsg : classes.offscreen}
        aria-live="assertive"
      >
        {errMsg}
      </p>
      <h1>Login</h1>
      <form className={classes.form} onSubmit={handleSubmit}>
        <InputContainer>
          <label htmlFor="email">Email:</label>
          <input
            type="text"
            id="email"
            ref={userRef}
            autoComplete="off"
            onChange={(e) => setEmail(e.target.value.toLowerCase())}
            value={email}
            required
          />
          <p
            id="uidnote"
            className={
              email && !validEmail ? classes.instructions : "hide"
            }
          >
            Must be a valid email address.
          </p>
        </InputContainer>
        <InputContainer>
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            required
          />
          <Link to="/reset_password">Forgot your password?</Link>
        </InputContainer>

        <Button disabled={!validEmail}>Sign In</Button>
      </form>
      <InfoBox className={classes.signup}>
        <span>Need an Account?</span>
        <Link to="/register">Sign Up</Link>
      </InfoBox>
      <InfoBox className={classes.signup}>
        <span>What changed?</span>
        <Link to="https://books.it-hafen.de/books/orkestermodellendk/page/changelog" target="_blank">Changelog</Link>
      </InfoBox>
    </Card>
  );
}

export default Login;
