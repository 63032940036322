import axios from "axios";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";

const baseURL = process.env.REACT_APP_API_URL;

/** Ein großer Axios Befehl mit Interceptor,
 * holt sich user aus aktuellen authToken
 * schaut nach ob dieses Access Token expired ist
 * falls nicht, gibt req zurück (keine ahnung warum)
 * falls ja, macht post request an refresh-api
 * setzt neue tokens in local Storage und AuthContext ein
 * gibt req mit Bearer auf und gibt req weiter
 */
function useAxios() {
  const { authTokens, setUser, setAuthTokens } = useContext(AuthContext);

  const axiosInstance = axios.create({
    baseURL,
    headers: { Authorization: `Bearer ${authTokens?.access}` },
  });

  axiosInstance.interceptors.request.use(async (req) => {
    const user = jwt_decode(authTokens.access);
    const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;

    if (!isExpired) {
      return req;
    }

    const response = await axios.post(`${baseURL}/refresh/`, {
      refresh: authTokens.refresh,
    });

    localStorage.setItem("authTokens", JSON.stringify(response.data));

    setAuthTokens(response.data);
    setUser(jwt_decode(response.data.access));

    req.headers.Authorization = `Bearer ${response.data.access}`;
    return req;
  });

  return axiosInstance;
}

export default useAxios;
