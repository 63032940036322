import {React, useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import styles from "../HomePage/Home.module.css";
import classes from "../UI/Button.module.css";
import welcome from "./Welcome.module.css"

const baseURL = process.env.REACT_APP_API_URL;
const confirmURL = "/confirm-email?token_id=";

const Welcome = () => {
  const { confirmationCode } = useParams();
  const [message, setMessage] = useState("");

  useEffect(() => {
    fetch(`${baseURL}${confirmURL}${confirmationCode}`)
      .then((res) => res.text())
      .then((data) => setMessage(data));
  }, []);

  return (
    <div className={welcome.login} style={{border: '1px solid black', borderRadius: '5px'}}>
      <h1>E-Mail confirmation</h1>
      <div className={styles.cardContainer}>
          <div className={welcome.home}>
            {message}
          </div>
        <Link to={"/login"} className={classes.button} style={{textDecoration:'black', color: 'black', display: 'flex', justifyContent:'center'}}>Please Login</Link>
      </div>
    </div>
  );
};

export default Welcome;
