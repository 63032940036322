import classes from "./EditableCell.module.css";
import { useRef, useState, useEffect, useLayoutEffect } from "react";
import useAxios from "../../utils/useAxios";
import Card from "../UI/Card";
import Modal from "../UI/Modal";
import Button from "../UI/Button";

const EditableCell = (props) => {
  const inputTextAreaRef = useRef(undefined);
  const [showModal, setShowModal] = useState(false);
  const [showExplanationModal, setShowExplanationModal] = useState(false);
  const [textArea, setTextArea] = useState("");
  const [errMsg, setErrMsg] = useState("");

  const axios = useAxios();

  useEffect(() => {
    setTextArea(cellText);
  }, [props.group])
  useLayoutEffect(()=>{
   setTextAreasHeight();
  })
  function cellText(){
    if(props.cellData.cellType=="conductor") {
       return props.group.conductorNotes;
    }
    if(props.cellData.cellType=="parent"){
      return props.group.parentNotes;
    }
  }
  function setTextAreasHeight() {
    if(inputTextAreaRef.current){

      inputTextAreaRef.current.style.height = inputTextAreaRef.current.scrollHeight+'px';
    }
  }
  function textAreaInputChange(e) {
    setTextArea(e.target.value);
    setTextAreasHeight();
  }
  async function saveTextArea() {
    try {
      const response = await axios.put(
        `/orkestergroup/${props.group.id}/notes`,
        {
          cellType: props.cellData.cellType,
          notes: textArea
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      setShowModal(false);
    } catch (error) {
      if (!error?.response) {
        setErrMsg("No Server Response!");
      } else if (error.response?.status === 409) {
        // Get messege from the response -> data.message (server is sending message: "xx")
        setErrMsg(error.response?.data.message);
      } else {
        setErrMsg("Registration Failed!");
      }
    }

  }
  return (
    <Card className={`${props.className} ${classes.cell}`}>
      <div
        className={`${classes.cellHead} ${props.colorWeight==99?classes.blueCategory:''}`}
        style={{ backgroundColor: props.color }}
      >
        <span className={classes.title}  onClick={()=>{setShowExplanationModal(true)}}>{props.cellData.title}<i className={classes.helpIcon} data-html2canvas-ignore="true">?</i></span>

 
        <span
          className={classes.subtitle}
          style={{ backgroundColor: props.color }}
        >
          <span>{props.cellData.description}</span>
        </span>
      </div>
      <div className={classes.cellBody} onClick={() => setShowModal(true)}>
        <div className={classes.displayTextArea} value={textArea}>  
        {textArea}
        </div>

      </div>
      <Modal
        title={props.cellData.title}
        show={showModal}
        onClose={()=>setShowModal(false)}
        headColor={{ backgroundColor: props.color }}
        >
          <textarea className={classes.textInput} onChange={(e) => textAreaInputChange(e)} value={textArea} ref={inputTextAreaRef}>
            
          </textarea>
          <div className={classes.modalButtons}>
        <Button className={classes.modalSaveButton} onClick={saveTextArea}>Save</Button>
        <Button className={classes.cancelButton} onClick={()=>setShowModal(false)}>Cancel</Button>
      </div>
      </Modal>
        <Modal
          title={props.cellData.title}
          show={showExplanationModal}
          onClose={() => setShowExplanationModal(false)}
          className={classes.explanationModal}
          headColor={{ backgroundColor: props.color }}
        >
          <p>{props.cellData.explanation}</p>
        </Modal>
    </Card>
  );
};

export default EditableCell;
